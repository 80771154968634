



































































































import OwnerPlayroomService from '@/services/owner/OwnerPlayroomService';
import Vue from 'vue';

class PlayroomEditContainerViewModel {
  id!: string;
  companyUrlPath!: string;
  playroomUrlPath!: string;
}

export default Vue.extend({
  props: {
    playroomId: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      currentRouteName: '',
      playroom: new PlayroomEditContainerViewModel(),
    };
  },
  computed: {
    hasCustomUrl(): boolean {
      return !!this.playroom.companyUrlPath && !!this.playroom.playroomUrlPath;
    },
    routeData(): { name: string; params: {} } {
      if (this.hasCustomUrl) {
        return {
          name: 'PublicCustomUrlPlayroom',
          params: {
            companyUrlPath: this.playroom.companyUrlPath,
            playroomUrlPath: this.playroom.playroomUrlPath,
          },
        };
      } else {
        return {
          name: 'PublicPlayroomDetail',
          params: { playroomId: this.playroom.id },
        };
      }
    },
  },
  methods: {
    loadData() {
      OwnerPlayroomService.getForEditContainer(this.playroomId).then((res) => {
        this.playroom = res.value as PlayroomEditContainerViewModel;
      });
    },
  },
  watch: {
    '$route.name': {
      deep: true,
      immediate: true,
      handler(name: string) {
        this.currentRouteName = name;
      },
    },
  },
});
