var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"edit-nav"},[_c('CNav',{attrs:{"fill":true,"variant":"tabs","justified":false}},[_c('li',{staticClass:"nav-item"},[_c('CLink',{staticClass:"nav-link",attrs:{"to":{
            name: 'OwnerPlayroomEditBasicInformation',
            params: { playroomId: _vm.playroomId },
          },"active":_vm.currentRouteName === 'OwnerPlayroomEditBasicInformation'}},[_vm._v(" "+_vm._s(_vm.$t('pages.owner.playrooms.edit.OwnerPlayroomEditContainer.basicInformations'))+" ")])],1),_c('li',{staticClass:"nav-item"},[_c('CLink',{staticClass:"nav-link",attrs:{"to":{
            name: 'OwnerPlayroomEditDetailInformation',
            params: { playroomId: _vm.playroomId },
          },"active":_vm.currentRouteName === 'OwnerPlayroomEditDetailInformation'}},[_vm._v(" "+_vm._s(_vm.$t('pages.owner.playrooms.edit.OwnerPlayroomEditContainer.detailInformations'))+" ")])],1),_c('li',{staticClass:"nav-item"},[_c('CLink',{staticClass:"nav-link",attrs:{"to":{
            name: 'OwnerPlayroomEditAvailability',
            params: { playroomId: _vm.playroomId },
          },"active":_vm.currentRouteName === 'OwnerPlayroomEditAvailability'}},[_vm._v(" "+_vm._s(_vm.$t('pages.owner.playrooms.edit.OwnerPlayroomEditContainer.availability'))+" ")])],1),_c('li',{staticClass:"nav-item"},[_c('CLink',{staticClass:"nav-link",attrs:{"to":{
            name: 'OwnerPlayroomEditPrice',
            params: { playroomId: _vm.playroomId },
          },"active":_vm.currentRouteName === 'OwnerPlayroomEditPrice'}},[_vm._v(" "+_vm._s(_vm.$t('pages.owner.playrooms.edit.OwnerPlayroomEditContainer.pricesAndOptions'))+" ")])],1),_c('li',{staticClass:"nav-item"},[_c('CLink',{staticClass:"nav-link",attrs:{"to":{
            name: 'OwnerPlayroomEditPaymentAndInvoice',
            params: { playroomId: _vm.playroomId },
          },"active":_vm.currentRouteName === 'OwnerPlayroomEditPaymentAndInvoice'}},[_vm._v(" "+_vm._s(_vm.$t('pages.owner.playrooms.edit.OwnerPlayroomEditContainer.paymentAndInvoice'))+" ")])],1),_c('li',{staticClass:"nav-item"},[_c('CLink',{staticClass:"nav-link",attrs:{"to":{
            name: 'OwnerPlayroomEditRules',
            params: { playroomId: _vm.playroomId },
          },"active":_vm.currentRouteName === 'OwnerPlayroomEditRules'}},[_vm._v(" "+_vm._s(_vm.$t('pages.owner.playrooms.edit.OwnerPlayroomEditContainer.houseRulesAndGeneralTerms'))+" ")])],1),_c('li',{staticClass:"nav-item"},[_c('CLink',{staticClass:"nav-link",attrs:{"to":{
            name: 'OwnerPlayroomEditCheckAndFinish',
            params: { playroomId: _vm.playroomId },
          },"active":_vm.currentRouteName === 'OwnerPlayroomEditCheckAndFinish'}},[_vm._v(" "+_vm._s(_vm.$t('pages.owner.playrooms.edit.OwnerPlayroomEditContainer.CheckAndFinish'))+" ")])],1)])],1),_c('div',{staticClass:"preview-button-container"},[(_vm.playroom.id)?_c('router-link',{staticClass:"preview btn btn-primary",attrs:{"to":_vm.routeData,"target":"_blank"}},[_vm._v(" Preview ")]):_vm._e()],1),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }