import ApiService, { ServerResponse } from '@/services/ApiService';
import PlayroomFeature from '@/models/PlayroomFeature';
import { PlayroomHighlightDto } from '@/models/PlayroomHighlightDto';
import PlayroomCustomFeature from '@/models/PlayroomCustomFeature';
const API_URL = '/api/Owner/Playroom/';

class OwnerPlayroomService {
  getForEditContainer(playroomId: string) {
    return ApiService.get(`${API_URL}EditContainer?playroomId=${playroomId}`);
  }

  saveVideoPart(playroom: { id: string; videoType: string; videoUrl: string; availableVideoTypes: string[] }) {
    return ApiService.post(`${API_URL}EditVideoPart?playroomId=${playroom.id}`, playroom);
  }

  getForEditVideoPart(playroomId: string) {
    return ApiService.get(`${API_URL}EditVideoPart?playroomId=${playroomId}`);
  }

  saveProductVariantPart(playroomId: string, playroom: { productVariant: string }) {
    return ApiService.post(`${API_URL}EditProductVariantPart?playroomId=${playroomId}`, playroom);
  }
  getForEditProductVariantPart(playroomId: string) {
    return ApiService.get(`${API_URL}EditProductVariantPart?playroomId=${playroomId}`);
  }

  saveMessagePart(playroom: { id: string }): any {
    return ApiService.post(`${API_URL}EditMessagePart?playroomId=${playroom.id}`, playroom);
  }

  getForEditMessagePart(playroomId: string) {
    return ApiService.get(`${API_URL}EditMessagePart?playroomId=${playroomId}`);
  }

  saveTemporalAvailabilityPart(
    playroomId: string,
    playroom: { alwaysAvailable: boolean | string }
  ): Promise<ServerResponse> {
    playroom.alwaysAvailable = playroom.alwaysAvailable.toString();
    return ApiService.post(`${API_URL}EditTemporalAvailabilityPart?playroomId=${playroomId}`, playroom);
  }

  getForEditTemporalAvailabilityPart(playroomId: string): Promise<ServerResponse> {
    return ApiService.get(`${API_URL}EditTemporalAvailabilityPart?playroomId=${playroomId}`);
  }

  saveFinalInformationPart(playroom: {
    id: string;
    activeByOwner: string | boolean;
    acceptBusinessTerms: string | boolean;
    acceptLegalTerms: string | boolean;
  }): any {
    playroom.activeByOwner = String(playroom.activeByOwner);
    playroom.acceptBusinessTerms = String(playroom.acceptBusinessTerms);
    playroom.acceptLegalTerms = String(playroom.acceptLegalTerms);
    return ApiService.post(`${API_URL}EditFinalInformationPart?playroomId=${playroom.id}`, playroom);
  }

  getForEditFinalInformationPart(playroomId: string) {
    return ApiService.get(`${API_URL}EditFinalInformationPart?playroomId=${playroomId}`);
  }

  saveManagerInformationPart(playroom: {
    id: string;
    latitude: string | number;
    longitude: string | number;
  }): Promise<ServerResponse> {
    playroom.latitude = String(playroom.latitude);
    playroom.longitude = String(playroom.longitude);
    return ApiService.post(`${API_URL}EditManagerInformationPart?playroomId=${playroom.id}`, playroom);
  }

  getForEditManagerInformationPart(playroomId: string) {
    return ApiService.get(`${API_URL}EditManagerInformationPart?playroomId=${playroomId}`);
  }

  getForEditOwnerInformationPart(playroomId: string) {
    return ApiService.get(`${API_URL}EditOwnerInformationPart?playroomId=${playroomId}`);
  }

  saveOwnerInformationPart(playroom: {
    id: string;
    latitude: string | number;
    longitude: string | number;
    ownerIsManager: string | boolean;
  }): Promise<ServerResponse> {
    playroom.latitude = String(playroom.latitude);
    playroom.longitude = String(playroom.longitude);
    playroom.ownerIsManager = String(playroom.ownerIsManager);
    return ApiService.post(`${API_URL}EditOwnerInformationPart?playroomId=${playroom.id}`, playroom);
  }

  saveSummaryPart(playroom: {
    id: string;
    latitude: string | number;
    longitude: string | number;
  }): Promise<ServerResponse> {
    playroom.latitude = String(playroom.latitude);
    playroom.longitude = String(playroom.longitude);
    return ApiService.post(`${API_URL}EditSummaryPart?playroomId=${playroom.id}`, playroom);
  }

  getForEditSummaryPart(playroomId: string) {
    return ApiService.get(`${API_URL}EditSummaryPart?playroomId=${playroomId}`);
  }

  saveFrequentlyAskedQuestions(playroom: { id: string }) {
    return ApiService.post(`${API_URL}EditFrequentlyAskedQuestionsPart?playroomId=${playroom.id}`, playroom);
  }

  getQuestion(playroomId: string) {
    return ApiService.get(`${API_URL}EditFrequentlyAskedQuestionsPart?playroomId=${playroomId}`);
  }

  saveHouseRules(playroom: { id: string }) {
    return ApiService.post(`${API_URL}EditHouseRulesPart?playroomId=${playroom.id}`, playroom);
  }

  getHouseRules(playroomId: string) {
    return ApiService.get(`${API_URL}EditHouseRulesPart?playroomId=${playroomId}`);
  }

  saveInvoiceInformationPart(playroom: { id: string }): Promise<ServerResponse> {
    return ApiService.post(`${API_URL}EditInvoiceInformationPart?playroomId=${playroom.id}`, playroom);
  }

  getForEditInvoiceInformationPart(playroomId: string): Promise<ServerResponse> {
    return ApiService.get(`${API_URL}EditInvoiceInformationPart?playroomId=${playroomId}`);
  }

  savePaymentAndInvoicePart(playroom: { id: string; paymentMethods: any[] }): Promise<ServerResponse> {
    return ApiService.post(`${API_URL}EditPlayroomPaymentAndInvoicePart?playroomId=${playroom.id}`, playroom);
  }

  getForEditPaymentAndInvoicePart(playroomId: string): Promise<ServerResponse> {
    return ApiService.get(`${API_URL}EditPaymentAndInvoicePart?playroomId=${playroomId}`);
  }

  savePrepaymentAndDepositPart(playroom: {
    id: string;
    prepaymentOnlyForNewGuests: boolean | string;
  }): Promise<ServerResponse> {
    playroom.prepaymentOnlyForNewGuests = String(playroom.prepaymentOnlyForNewGuests);
    return ApiService.post(`${API_URL}EditPlayroomPrepaymentAndDepositPart?playroomId=${playroom.id}`, playroom);
  }

  getForEditPrepaymentAndDepositPart(playroomId: string): Promise<ServerResponse> {
    return ApiService.get(`${API_URL}EditPrepaymentAndDepositPart?playroomId=${playroomId}`);
  }

  deleteBusinessTerms(playroomId: string): Promise<ServerResponse> {
    return ApiService.delete(`${API_URL}DeleteBusinessTerms?playroomId=${playroomId}`);
  }

  deleteHouseRules(playroomId: string): Promise<ServerResponse> {
    return ApiService.delete(`${API_URL}DeleteHouseRules?playroomId=${playroomId}`);
  }

  saveHouseRulesAndBusinessTermsPart(playroom: {}): Promise<ServerResponse> {
    return ApiService.post(`${API_URL}EditPlayroomHouseRulesAndBusinessTermsPart`, playroom);
  }

  getForEditHouseRulesAndBusinessTermsPart(playroomId: string): Promise<ServerResponse> {
    return ApiService.get(`${API_URL}EditPlayroomHouseRulesAndBusinessTermsPart?playroomId=${playroomId}`);
  }

  getForEditPlayroomAdditionPart(playroomId: string): Promise<ServerResponse> {
    return ApiService.get(`${API_URL}EditPlayroomAdditionPart?playroomId=${playroomId}`);
  }

  saveBasicChargePart(
    playroomId: string,
    playroom: {
      id: string;
      allowSurchargePerPersonPerBookingForHourly: string | boolean;
      allowSurchargePerPersonPerBookingForOvernight: string | boolean;
      allowSurchargePerPersonPerHour: string | boolean;
      allowSurchargePerPersonPerNight: string | boolean;
      baseRentForHourly: string | number;
      baseRentForOvernight: string | number;
      surchargePerPersonPerBookingForHourly: string | number;
      surchargePerPersonPerBookingForOvernight: string | number;
      surchargePerPersonPerHour: string | number;
      surchargePerPersonPerNight: string | number;
      bedTax: string | number;
    }
  ): Promise<ServerResponse> {
    playroom.allowSurchargePerPersonPerBookingForHourly = String(playroom.allowSurchargePerPersonPerBookingForHourly);
    playroom.allowSurchargePerPersonPerBookingForOvernight = String(
      playroom.allowSurchargePerPersonPerBookingForOvernight
    );
    playroom.allowSurchargePerPersonPerHour = String(playroom.allowSurchargePerPersonPerHour);
    playroom.allowSurchargePerPersonPerNight = String(playroom.allowSurchargePerPersonPerNight);
    playroom.baseRentForHourly = String(playroom.baseRentForHourly);
    playroom.baseRentForOvernight = String(playroom.baseRentForOvernight);
    playroom.surchargePerPersonPerBookingForHourly = String(playroom.surchargePerPersonPerBookingForHourly);
    playroom.surchargePerPersonPerBookingForOvernight = String(playroom.surchargePerPersonPerBookingForOvernight);
    playroom.surchargePerPersonPerHour = String(playroom.surchargePerPersonPerHour);
    playroom.surchargePerPersonPerNight = String(playroom.surchargePerPersonPerNight);
    playroom.bedTax = String(playroom.bedTax);
    return ApiService.post(`${API_URL}EditBasicChargePart?playroomId=${playroomId}`, playroom);
  }

  getForEditBasicChargePart(playroomId: string): Promise<ServerResponse> {
    return ApiService.get(`${API_URL}EditBasicChargePart?playroomId=${playroomId}`);
  }

  saveBookingTimeRestrictionsPart(
    playroomId: string,
    playroom: {
      allowOvernightBooking: boolean | string;
      allowHourBooking: boolean | string;
      overnightCheckInStartAt: number | string;
      overnightCheckInEndAt: number | string;
      hourCheckInStartAt: number | string;
      hourCheckInEndAt: number | string;
      minutesForCleaning: number | string;
      minimalBookingHours: number | string;
      minimalBookingNights: number | string;
      overnightCheckOutStartAt: number | string;
      overnightCheckOutEndAt: number | string;
      allowedDaysForHourlyBooking: {
        onMonday: boolean | string;
        onTuesday: boolean | string;
        onWednesday: boolean | string;
        onThursday: boolean | string;
        onFriday: boolean | string;
        onSaturday: boolean | string;
        onSunday: boolean | string;
        beforeHoliday: boolean | string;
        onHoliday: boolean | string;
      };
      allowedDaysForHourlyCheckIn: {
        onMonday: boolean | string;
        onTuesday: boolean | string;
        onWednesday: boolean | string;
        onThursday: boolean | string;
        onFriday: boolean | string;
        onSaturday: boolean | string;
        onSunday: boolean | string;
        beforeHoliday: boolean | string;
        onHoliday: boolean | string;
      };
      allowedDaysForHourlyCheckOut: {
        onMonday: boolean | string;
        onTuesday: boolean | string;
        onWednesday: boolean | string;
        onThursday: boolean | string;
        onFriday: boolean | string;
        onSaturday: boolean | string;
        onSunday: boolean | string;
        beforeHoliday: boolean | string;
        onHoliday: boolean | string;
      };
      allowedDaysForOvernightBooking: {
        onMonday: boolean | string;
        onTuesday: boolean | string;
        onWednesday: boolean | string;
        onThursday: boolean | string;
        onFriday: boolean | string;
        onSaturday: boolean | string;
        onSunday: boolean | string;
        beforeHoliday: boolean | string;
        onHoliday: boolean | string;
      };
      allowedDaysForOvernightCheckIn: {
        onMonday: boolean | string;
        onTuesday: boolean | string;
        onWednesday: boolean | string;
        onThursday: boolean | string;
        onFriday: boolean | string;
        onSaturday: boolean | string;
        onSunday: boolean | string;
        beforeHoliday: boolean | string;
        onHoliday: boolean | string;
      };
      allowedDaysForOvernightCheckOut: {
        onMonday: boolean | string;
        onTuesday: boolean | string;
        onWednesday: boolean | string;
        onThursday: boolean | string;
        onFriday: boolean | string;
        onSaturday: boolean | string;
        onSunday: boolean | string;
        beforeHoliday: boolean | string;
        onHoliday: boolean | string;
      };
    }
  ): Promise<ServerResponse> {
    playroom.allowOvernightBooking = String(playroom.allowOvernightBooking);
    playroom.allowHourBooking = String(playroom.allowHourBooking);
    playroom.overnightCheckInStartAt = String(playroom.overnightCheckInStartAt);
    playroom.overnightCheckInEndAt = String(playroom.overnightCheckInEndAt);
    playroom.hourCheckInStartAt = String(playroom.hourCheckInStartAt);
    playroom.hourCheckInEndAt = String(playroom.hourCheckInEndAt);
    playroom.minutesForCleaning = String(playroom.minutesForCleaning);
    playroom.minimalBookingHours = String(playroom.minimalBookingHours);
    playroom.minimalBookingNights = String(playroom.minimalBookingNights);
    playroom.overnightCheckOutStartAt = String(playroom.overnightCheckOutStartAt);
    playroom.overnightCheckOutEndAt = String(playroom.overnightCheckOutEndAt);
    // ['Booking', 'CheckIn', 'CheckOut'].forEach((option) => {
    //   ['Hourly', 'Overnight'].forEach((bookingType) => {
    //     [
    //       'onMonday',
    //       'onTuesday',
    //       'onWednesday',
    //       'onThursday',
    //       'onFriday',
    //       'onSaturday',
    //       'onSunday',
    //       'beforeHoliday',
    //       'onHoliday',
    //     ].forEach((day) => {
    //       const key = ('allowedDaysFor' + bookingType + option) as keyof typeof playroom;
    //       const dayKey = day as keyof typeof AllowedDaysDto;
    //       const value = playroom[key] as AllowedDaysDto;
    //       (playroom[key] as any)[dayKey] = String(value);
    //     });
    //   });
    // });
    playroom.allowedDaysForHourlyBooking.onMonday = String(playroom.allowedDaysForHourlyBooking.onMonday);
    playroom.allowedDaysForHourlyBooking.onTuesday = String(playroom.allowedDaysForHourlyBooking.onTuesday);
    playroom.allowedDaysForHourlyBooking.onWednesday = String(playroom.allowedDaysForHourlyBooking.onWednesday);
    playroom.allowedDaysForHourlyBooking.onThursday = String(playroom.allowedDaysForHourlyBooking.onThursday);
    playroom.allowedDaysForHourlyBooking.onFriday = String(playroom.allowedDaysForHourlyBooking.onFriday);
    playroom.allowedDaysForHourlyBooking.onSaturday = String(playroom.allowedDaysForHourlyBooking.onSaturday);
    playroom.allowedDaysForHourlyBooking.onSunday = String(playroom.allowedDaysForHourlyBooking.onSunday);
    playroom.allowedDaysForHourlyBooking.beforeHoliday = String(playroom.allowedDaysForHourlyBooking.beforeHoliday);
    playroom.allowedDaysForHourlyBooking.onHoliday = String(playroom.allowedDaysForHourlyBooking.onHoliday);
    playroom.allowedDaysForHourlyCheckIn.onMonday = String(playroom.allowedDaysForHourlyCheckIn.onMonday);
    playroom.allowedDaysForHourlyCheckIn.onTuesday = String(playroom.allowedDaysForHourlyCheckIn.onTuesday);
    playroom.allowedDaysForHourlyCheckIn.onWednesday = String(playroom.allowedDaysForHourlyCheckIn.onWednesday);
    playroom.allowedDaysForHourlyCheckIn.onThursday = String(playroom.allowedDaysForHourlyCheckIn.onThursday);
    playroom.allowedDaysForHourlyCheckIn.onFriday = String(playroom.allowedDaysForHourlyCheckIn.onFriday);
    playroom.allowedDaysForHourlyCheckIn.onSaturday = String(playroom.allowedDaysForHourlyCheckIn.onSaturday);
    playroom.allowedDaysForHourlyCheckIn.onSunday = String(playroom.allowedDaysForHourlyCheckIn.onSunday);
    playroom.allowedDaysForHourlyCheckIn.beforeHoliday = String(playroom.allowedDaysForHourlyCheckIn.beforeHoliday);
    playroom.allowedDaysForHourlyCheckIn.onHoliday = String(playroom.allowedDaysForHourlyCheckIn.onHoliday);
    playroom.allowedDaysForHourlyCheckOut.onMonday = String(playroom.allowedDaysForHourlyCheckOut.onMonday);
    playroom.allowedDaysForHourlyCheckOut.onTuesday = String(playroom.allowedDaysForHourlyCheckOut.onTuesday);
    playroom.allowedDaysForHourlyCheckOut.onWednesday = String(playroom.allowedDaysForHourlyCheckOut.onWednesday);
    playroom.allowedDaysForHourlyCheckOut.onThursday = String(playroom.allowedDaysForHourlyCheckOut.onThursday);
    playroom.allowedDaysForHourlyCheckOut.onFriday = String(playroom.allowedDaysForHourlyCheckOut.onFriday);
    playroom.allowedDaysForHourlyCheckOut.onSaturday = String(playroom.allowedDaysForHourlyCheckOut.onSaturday);
    playroom.allowedDaysForHourlyCheckOut.onSunday = String(playroom.allowedDaysForHourlyCheckOut.onSunday);
    playroom.allowedDaysForHourlyCheckOut.beforeHoliday = String(playroom.allowedDaysForHourlyCheckOut.beforeHoliday);
    playroom.allowedDaysForHourlyCheckOut.onHoliday = String(playroom.allowedDaysForHourlyCheckOut.onHoliday);
    playroom.allowedDaysForOvernightBooking.onMonday = String(playroom.allowedDaysForOvernightBooking.onMonday);
    playroom.allowedDaysForOvernightBooking.onTuesday = String(playroom.allowedDaysForOvernightBooking.onTuesday);
    playroom.allowedDaysForOvernightBooking.onWednesday = String(playroom.allowedDaysForOvernightBooking.onWednesday);
    playroom.allowedDaysForOvernightBooking.onThursday = String(playroom.allowedDaysForOvernightBooking.onThursday);
    playroom.allowedDaysForOvernightBooking.onFriday = String(playroom.allowedDaysForOvernightBooking.onFriday);
    playroom.allowedDaysForOvernightBooking.onSaturday = String(playroom.allowedDaysForOvernightBooking.onSaturday);
    playroom.allowedDaysForOvernightBooking.onSunday = String(playroom.allowedDaysForOvernightBooking.onSunday);
    playroom.allowedDaysForOvernightBooking.beforeHoliday = String(
      playroom.allowedDaysForOvernightBooking.beforeHoliday
    );
    playroom.allowedDaysForOvernightBooking.onHoliday = String(playroom.allowedDaysForOvernightBooking.onHoliday);
    playroom.allowedDaysForOvernightCheckIn.onMonday = String(playroom.allowedDaysForOvernightCheckIn.onMonday);
    playroom.allowedDaysForOvernightCheckIn.onTuesday = String(playroom.allowedDaysForOvernightCheckIn.onTuesday);
    playroom.allowedDaysForOvernightCheckIn.onWednesday = String(playroom.allowedDaysForOvernightCheckIn.onWednesday);
    playroom.allowedDaysForOvernightCheckIn.onThursday = String(playroom.allowedDaysForOvernightCheckIn.onThursday);
    playroom.allowedDaysForOvernightCheckIn.onFriday = String(playroom.allowedDaysForOvernightCheckIn.onFriday);
    playroom.allowedDaysForOvernightCheckIn.onSaturday = String(playroom.allowedDaysForOvernightCheckIn.onSaturday);
    playroom.allowedDaysForOvernightCheckIn.onSunday = String(playroom.allowedDaysForOvernightCheckIn.onSunday);
    playroom.allowedDaysForOvernightCheckIn.beforeHoliday = String(
      playroom.allowedDaysForOvernightCheckIn.beforeHoliday
    );
    playroom.allowedDaysForOvernightCheckIn.onHoliday = String(playroom.allowedDaysForOvernightCheckIn.onHoliday);
    playroom.allowedDaysForOvernightCheckOut.onMonday = String(playroom.allowedDaysForOvernightCheckOut.onMonday);
    playroom.allowedDaysForOvernightCheckOut.onTuesday = String(playroom.allowedDaysForOvernightCheckOut.onTuesday);
    playroom.allowedDaysForOvernightCheckOut.onWednesday = String(playroom.allowedDaysForOvernightCheckOut.onWednesday);
    playroom.allowedDaysForOvernightCheckOut.onThursday = String(playroom.allowedDaysForOvernightCheckOut.onThursday);
    playroom.allowedDaysForOvernightCheckOut.onFriday = String(playroom.allowedDaysForOvernightCheckOut.onFriday);
    playroom.allowedDaysForOvernightCheckOut.onSaturday = String(playroom.allowedDaysForOvernightCheckOut.onSaturday);
    playroom.allowedDaysForOvernightCheckOut.onSunday = String(playroom.allowedDaysForOvernightCheckOut.onSunday);
    playroom.allowedDaysForOvernightCheckOut.beforeHoliday = String(
      playroom.allowedDaysForOvernightCheckOut.beforeHoliday
    );
    playroom.allowedDaysForOvernightCheckOut.onHoliday = String(playroom.allowedDaysForOvernightCheckOut.onHoliday);
    return ApiService.post(`${API_URL}EditBookingTimeRestrictionsPart?playroomId=${playroomId}`, playroom);
  }

  getForEditBookingTimeRestrictionsPart(playroomId: string): Promise<ServerResponse> {
    return ApiService.get(`${API_URL}EditBookingTimeRestrictionsPart?playroomId=${playroomId}`);
  }

  saveRatesPart(playroom: { id: string }): Promise<ServerResponse> {
    return ApiService.post(`${API_URL}EditRatesPart?playroomId=${playroom.id}`, playroom);
  }

  getForEditRatesPart(playroomId: string): Promise<ServerResponse> {
    return ApiService.get(`${API_URL}EditRatesPart?playroomId=${playroomId}`);
  }

  saveRoomPart(playroomId: string): Promise<ServerResponse> {
    return ApiService.post(`${API_URL}EditCustomFeature?playroomId=${playroomId}`, playroomId);
  }

  getForEditRoomPart(playroomId: string): Promise<ServerResponse> {
    return ApiService.get(`${API_URL}EditRoomPart?playroomId=${playroomId}`);
  }

  saveAdvancedBookingPart(
    playroomId: string,
    playroom: { bookingMaxDaysInAdvance: number | string; bookingMinHoursInAdvance: number | string }
  ): Promise<ServerResponse> {
    playroom.bookingMaxDaysInAdvance = String(playroom.bookingMaxDaysInAdvance);
    playroom.bookingMinHoursInAdvance = String(playroom.bookingMinHoursInAdvance);
    return ApiService.post(`${API_URL}EditAdvancedBookingPart?playroomId=${playroomId}`, playroom);
  }

  getForEditAdvancedBookingPart(playroomId: string): Promise<ServerResponse> {
    return ApiService.get(`${API_URL}EditAdvancedBookingPart?playroomId=${playroomId}`);
  }

  saveCustomFeaturePart(playroomId: string, playroomFeature: PlayroomFeature): Promise<ServerResponse> {
    return ApiService.post(`${API_URL}EditCustomFeature?playroomId=${playroomId}`, playroomFeature);
  }

  saveFeaturePart(
    playroomId: string,
    playroomFeatures: PlayroomFeature[],
    playroomCustomFeatures: PlayroomCustomFeature[]
  ): Promise<ServerResponse> {
    return ApiService.post(`${API_URL}EditFeature?playroomId=${playroomId}`, {
      playroomFeatures,
      playroomCustomFeatures,
    });
  }

  getForEditFeaturePart(playroomId: string): Promise<ServerResponse> {
    return ApiService.get(`${API_URL}EditFeaturePart?playroomId=${playroomId}`);
  }

  deleteImage(playroomImageId: string): Promise<ServerResponse> {
    return ApiService.delete(`${API_URL}DeleteImagePart?playroomImageId=${playroomImageId}`);
  }

  getForEditImagePart(playroomId: string): Promise<ServerResponse> {
    return ApiService.get(`${API_URL}EditImagePart?playroomId=${playroomId}`);
  }

  editSaveNewImage(playroomImage: {}): Promise<ServerResponse> {
    return ApiService.post(`${API_URL}EditSaveNewImage`, playroomImage);
  }

  saveNamePart(playroom: {
    id: string;
    roomCount: string | number;
    areaInSquareMeter: string | number;
  }): Promise<ServerResponse> {
    playroom.areaInSquareMeter = String(playroom.areaInSquareMeter);
    playroom.roomCount = String(playroom.roomCount);
    return ApiService.post(`${API_URL}EditNamePart`, playroom);
  }
  saveContactPart(playroom: { id: string; phoneNumber: string | number; email: string }): Promise<ServerResponse> {
    return ApiService.post(`${API_URL}EditContactPart`, playroom);
  }
  saveAddressPart(playroom: {
    id: string;
    latitude: string | number;
    longitude: string | number;
  }): Promise<ServerResponse> {
    playroom.latitude = String(playroom.latitude);
    playroom.longitude = String(playroom.longitude);
    return ApiService.post(`${API_URL}EditAddressPart`, { ...playroom, playroomId: playroom.id });
  }

  getForEditNamePart(playroomId: string): Promise<ServerResponse> {
    return ApiService.get(`${API_URL}EditNamePart?playroomId=${playroomId}`);
  }

  getForEditContactPart(playroomId: string): Promise<ServerResponse> {
    return ApiService.get(`${API_URL}EditContactPart?playroomId=${playroomId}`);
  }

  getForEditAddressPart(playroomId: string): Promise<ServerResponse> {
    return ApiService.get(`${API_URL}EditAddressPart?playroomId=${playroomId}`);
  }

  getForEditBasicInformation(playroomId: string): Promise<ServerResponse> {
    return ApiService.get(`${API_URL}EditBasicInformation?playroomId=${playroomId}`);
  }

  uploadFile(playroomId: string, file: File, typeName: string): Promise<ServerResponse> {
    const formData = new FormData();
    formData.append('file', file);
    return ApiService.post(`${API_URL}UploadFile?playroomId=${playroomId}&typeName=${typeName}`, formData, {
      'content-type': 'multipart/form-data',
    });
  }

  isUniqueUrlPath(value: string, playroomId: string): Promise<ServerResponse> {
    return ApiService.get(`${API_URL}IsUniqueUrlPath?urlPath=${value}&playroomId=${playroomId}`);
  }

  getDetail(playroomId: string): Promise<ServerResponse> {
    return ApiService.get(`${API_URL}Detail?playroomId=${playroomId}`);
  }

  delete(playroomId: string): Promise<ServerResponse> {
    return ApiService.delete(`${API_URL}Delete?playroomId=${playroomId}`);
  }

  uploadPreviewImage(playroomId: string, dataUrl: string, name: string): Promise<ServerResponse> {
    return ApiService.post(`${API_URL}UploadPreviewImage?playroomId=${playroomId}`, {
      imageBase64: dataUrl,
      name: name,
    });
  }

  createNew(playroom: { name: string; playroomIdToCopy: string }): Promise<ServerResponse> {
    if (playroom.playroomIdToCopy != '') {
      return ApiService.post(`${API_URL}Copy`, playroom);
    } else {
      return ApiService.post(`${API_URL}Create`, playroom);
    }
  }

  getIndex(currentPage: number, sortColumn: string, sortAsc: boolean, tableFilter: string): Promise<ServerResponse> {
    return ApiService.get(
      `${API_URL}Index?currentPage=${currentPage}&sortColumn=${sortColumn}&sortAsc=${sortAsc}&tableFilter=${tableFilter}`
    );
  }

  getHighlight(playroomId: string): Promise<ServerResponse> {
    return ApiService.get(`${API_URL}EditHighlightPart?playroomId=${playroomId}`);
  }

  saveHighlightPart(playroomId: string, highlights: PlayroomHighlightDto[]): Promise<ServerResponse> {
    return ApiService.post(`${API_URL}EditHighlightPart?playroomId=${playroomId}`, highlights);
  }
}

export default new OwnerPlayroomService();
